export default function AllCategories() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3335 16.6666H11.6668C15.0002 16.6666 16.6668 15 16.6668 11.6666V8.33331C16.6668 4.99998 15.0002 3.33331 11.6668 3.33331H8.3335C5.00016 3.33331 3.3335 4.99998 3.3335 8.33331V11.6666C3.3335 15 5.00016 16.6666 8.3335 16.6666Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.3335 16.6666H31.6668C35.0002 16.6666 36.6668 15 36.6668 11.6666V8.33331C36.6668 4.99998 35.0002 3.33331 31.6668 3.33331H28.3335C25.0002 3.33331 23.3335 4.99998 23.3335 8.33331V11.6666C23.3335 15 25.0002 16.6666 28.3335 16.6666Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.3335 36.6666H31.6668C35.0002 36.6666 36.6668 35 36.6668 31.6666V28.3333C36.6668 25 35.0002 23.3333 31.6668 23.3333H28.3335C25.0002 23.3333 23.3335 25 23.3335 28.3333V31.6666C23.3335 35 25.0002 36.6666 28.3335 36.6666Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.3335 36.6666H11.6668C15.0002 36.6666 16.6668 35 16.6668 31.6666V28.3333C16.6668 25 15.0002 23.3333 11.6668 23.3333H8.3335C5.00016 23.3333 3.3335 25 3.3335 28.3333V31.6666C3.3335 35 5.00016 36.6666 8.3335 36.6666Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
