export default function EBooks() {
  return (
    <svg
      width="36"
      height="41"
      viewBox="0 0 36 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8385 36.3669C11.5265 36.3669 11.2717 36.6217 11.2717 36.9386C11.2717 37.2533 11.5243 37.5102 11.8385 37.5102H23.9553C24.2673 37.5102 24.522 37.2554 24.522 36.9386C24.522 36.6239 24.2694 36.3669 23.9553 36.3669H11.8385Z"
        stroke="none"
      />
      <path
        d="M19.6333 3.7314C19.9454 3.7314 20.2001 3.47664 20.2001 3.15977C20.2001 2.84506 19.9475 2.58813 19.6333 2.58813H16.1626C15.8506 2.58813 15.5958 2.8429 15.5958 3.15977C15.5958 3.47448 15.8484 3.7314 16.1626 3.7314H19.6333Z"
        stroke="none"
      />
      <path
        d="M33.4249 8.65733H32.1173V5.83568C32.1173 5.66013 32.0366 5.49528 31.9008 5.38609C31.7628 5.27691 31.5866 5.24052 31.4147 5.28119L30.6632 5.46959V1.60102C30.6632 0.898839 30.0964 0.329346 29.4023 0.329346H6.3937C5.69746 0.329346 5.13279 0.900977 5.13279 1.60102V5.45457L4.43868 5.28116C4.26886 5.24048 4.09055 5.27687 3.95257 5.38606C3.81459 5.49525 3.73604 5.66009 3.73604 5.83564V8.6573H2.57703C1.1569 8.6573 0 9.82407 0 11.2563V30.5071C0 31.9415 1.15478 33.1061 2.57703 33.1061H5.13281V39.0192C5.13281 39.7214 5.6996 40.293 6.39372 40.293H29.4023C30.0986 40.293 30.6632 39.7214 30.6632 39.0192V35.2277C30.6632 34.913 30.4106 34.656 30.0964 34.656C29.7844 34.656 29.5296 34.9108 29.5296 35.2277V39.0192C29.5296 39.092 29.4723 39.1498 29.4002 39.1498H6.39373C6.32156 39.1498 6.26424 39.092 6.26424 39.0192V33.1061H33.423C34.8431 33.1061 36 31.9393 36 30.5071V11.2563C36 9.82193 34.845 8.65733 33.4249 8.65733ZM18.4932 9.68281L30.9854 6.56356V28.6637L18.4932 31.783V9.68281ZM6.26397 1.60106C6.26397 1.52827 6.32128 1.47046 6.39346 1.47046H29.4021C29.4742 1.47046 29.5315 1.52827 29.5315 1.60106V5.75007L17.9267 8.6467L6.26427 5.73511L6.26397 1.60106ZM4.86932 6.56362L17.3616 9.68287V31.783L4.86932 28.6638V6.56362ZM1.13328 30.5053V11.2545C1.13328 10.4517 1.78072 9.79654 2.57672 9.79654H3.73574V29.107C3.73574 29.3704 3.91193 29.5973 4.16666 29.6615L14.0929 31.9608L2.57714 31.9629C1.78113 31.9629 1.13328 31.3103 1.13328 30.5053ZM34.868 30.5053C34.868 31.3081 34.2206 31.9632 33.4246 31.9632H21.7599L31.6861 29.6639C31.9409 29.6018 32.1171 29.3728 32.1171 29.1116V9.79889H33.4246C34.2206 9.79889 34.8681 10.4519 34.8681 11.2569L34.868 30.5053Z"
        stroke="none"
      />
      <path
        d="M14.5366 15.1551L8.30623 13.1919C8.00267 13.0956 7.69063 13.2669 7.59722 13.5666C7.50382 13.8663 7.66939 14.1874 7.9687 14.2816L14.1969 16.2448C14.2542 16.2619 14.3115 16.2705 14.3667 16.2705C14.6087 16.2705 14.8316 16.1142 14.908 15.8701C14.9993 15.5683 14.8338 15.2493 14.5366 15.1551Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M14.5366 19.4732L8.30623 17.51C8.00267 17.4136 7.69063 17.5828 7.59722 17.8846C7.50382 18.1844 7.66939 18.5055 7.9687 18.5997L14.1969 20.5629C14.2542 20.58 14.3115 20.5886 14.3667 20.5886C14.6087 20.5886 14.8316 20.4323 14.908 20.1882C14.9993 19.8885 14.8338 19.5674 14.5366 19.4732Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M14.5366 23.7934L8.30623 21.8302C8.00267 21.736 7.69063 21.903 7.59722 22.2049C7.50382 22.5046 7.66939 22.8257 7.9687 22.9199L14.1969 24.8831C14.2542 24.9002 14.3115 24.9088 14.3667 24.9088C14.6087 24.9088 14.8316 24.7525 14.908 24.5085C14.9993 24.2066 14.8338 23.8876 14.5366 23.7934Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M27.5978 13.1921L21.3696 15.1553C21.0703 15.2495 20.9047 15.5685 20.9981 15.8703C21.0745 16.1144 21.2974 16.2707 21.5394 16.2707C21.5946 16.2707 21.6519 16.2621 21.7092 16.245L27.9374 14.2818C28.2367 14.1876 28.4023 13.8686 28.3089 13.5668C28.2134 13.267 27.9035 13.0979 27.5978 13.1921Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M27.5978 17.5102L21.3696 19.4734C21.0703 19.5676 20.9047 19.8866 20.9981 20.1884C21.0745 20.4325 21.2974 20.5888 21.5394 20.5888C21.5946 20.5888 21.6519 20.5802 21.7092 20.5631L27.9374 18.5999C28.2367 18.5057 28.4023 18.1867 28.3089 17.8849C28.2134 17.5851 27.9035 17.416 27.5978 17.5102Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M27.5978 21.8302L21.3696 23.7934C21.0703 23.8876 20.9047 24.2066 20.9981 24.5085C21.0745 24.7525 21.2974 24.9088 21.5394 24.9088C21.5946 24.9088 21.6519 24.9002 21.7092 24.8831L27.9374 22.9199C28.2367 22.8257 28.4023 22.5067 28.3089 22.2049C28.2134 21.903 27.9035 21.736 27.5978 21.8302Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  );
}
