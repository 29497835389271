import AllCategories from "~/svgs/blogTags/AllCategories";
import Articles from "~/svgs/blogTags/Articles";
import CaseStudies from "~/svgs/blogTags/CaseStudies";
import EBooks from "~/svgs/blogTags/EBooks";
import Reports from "~/svgs/blogTags/Reports";
import Webinars from "~/svgs/blogTags/Webinars";
import { SVG_ACTIVE_COLOR, SVG_INACTIVE_COLOR } from "~/utils/constants";

export interface Category {
  id: number;
  name: string;
  iName?: string;
  icon: JSX.Element;
}

export const ArticlesCategory = {
  id: 1,
  name_ar: "المقالات",
  name: "Articles",
  iName: "Article",
  icon: <Articles />,
};

export const CaseStudiesCategory = {
  id: 2,
  name_ar: "دراسات الحالة",
  name: "Case Studies",
  iName: "Case Study",
  icon: <CaseStudies />,
};

export const ReportsCategory = {
  id: 3,
  name_ar: "التقارير",
  name: "Reports",
  iName: "Report",
  icon: <Reports />,
};

export const webinarsCategory = {
  id: 4,
  name_ar: "الندوات",
  name: "Webinars",
  iName: "Webinar",
  icon: <Webinars />,
};

export const eBooksCategory = {
  id: 5,
  name_ar: "الكتب الالكترونية",
  name: "E-books",
  iName: "E-book",
  icon: <EBooks />,
};

export const AllId = 6;

export const allCategories = {
  id: 6,
  name_ar: "الكل",
  name: "View all",
  icon: <AllCategories />,
};

const categories = [
  ArticlesCategory,
  CaseStudiesCategory,
  ReportsCategory,
  webinarsCategory,
  eBooksCategory,
];

export enum CategoryType {
  Articles = 1,
  CaseStudies = 2,
  Reports = 3,
  Webinars = 4,
  EBook = 5,
  All = 6,
}

export default categories;
