export default function Reports() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6169 15.116H29.3669"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6331 15.1159L11.8831 16.3648L15.6331 12.6182"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.6169 26.772H29.3669"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6331 26.7719L11.8831 28.0208L15.6331 24.2742"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9999 36.9627H24.9999C33.3333 36.9627 36.6666 33.6324 36.6666 25.3066V15.3157C36.6666 6.98997 33.3333 3.65967 24.9999 3.65967H14.9999C6.66659 3.65967 3.33325 6.98997 3.33325 15.3157V25.3066C3.33325 33.6324 6.66659 36.9627 14.9999 36.9627Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
