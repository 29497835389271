export default function CaseStudies() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6665 36.9627H34.6332C36.7832 36.9627 38.4665 35.1477 38.3165 32.9997L36.6665 10.3203"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.5503 11.5858L30.9336 34.1154C30.5336 35.7972 29.0337 36.9627 27.3003 36.9627H5.4003C2.88363 36.9627 1.08366 34.4982 1.83366 32.0838L8.85031 9.57108C9.33364 8.00584 10.7837 6.92334 12.417 6.92334H32.917C34.5003 6.92334 35.817 7.88914 36.367 9.22126C36.6837 9.93727 36.7503 10.7533 36.5503 11.5858Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M16.1335 10.9531L17.8669 3.75977"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.2998 10.9696L28.8665 3.74292"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8337 20.3113H26.1671"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.167 26.9717H24.5003"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
