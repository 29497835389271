export default function Articles() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 11.9854V28.6369C35 33.6324 32.5 36.9627 26.6667 36.9627H13.3333C7.5 36.9627 5 33.6324 5 28.6369V11.9854C5 6.98997 7.5 3.65967 13.3333 3.65967H26.6667C32.5 3.65967 35 6.98997 35 11.9854Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.1666 7.82251V11.1528C24.1666 12.9845 25.6666 14.4831 27.5 14.4831H30.8333"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3334 21.9763H20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3334 28.637H26.6667"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
